import request from '@/utils/request.js'
import { urlPath } from './apiBaseInfo'

// 查询环境变量列表
export const queryApiEnvVarList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiEnvVar',
    params: {
      ...data
    }
  })
}

// 添加环境变量
export const addApiEnvVar = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'apiEnvVar',
    data
  })
}

// 编辑环境变量
export const editApiEnvVar = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'apiEnvVar',
    data
  })
}

// 删除用例
export const deleteApiEnvVar = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'apiEnvVar',
    data
  })
}
