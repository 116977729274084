<template>
  <div class="apiEnvVar">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        环境变量管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增环境变量</el-button>
      </div>
      <!-- 当前环境变量信息展示 -->
      <el-table
        :data="tableData">
        <el-table-column
          prop="id"
          label="环境变量编号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="var_key"
          label="环境变量key">
        </el-table-column>
        <el-table-column
          prop="var_value"
          label="环境变量value">
        </el-table-column>
        <el-table-column
          prop="env_id"
          label="所属环境变量">
        </el-table-column>
        <el-table-column
          prop="description"
          label="环境变量描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="环境变量状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试环境变量分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleEnvVar"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormEnvVar" :rules="apiEnvVarFormRules" ref="ruleFormRefEnvVar" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="环境ID">
          <el-input v-model="ruleFormEnvVar.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="环境变量key" prop="env_name">
          <el-input v-model="ruleFormEnvVar.var_key"></el-input>
        </el-form-item>
        <el-form-item label="环境变量value" prop="env_name">
          <el-input v-model="ruleFormEnvVar.var_value"></el-input>
        </el-form-item>
        <el-form-item label="所属环境" prop="env_name">
          <!-- <el-input v-model="ruleFormEnvVar.env_id"></el-input> -->
          <el-select v-model="ruleFormEnvVar.env_id" filterable placeholder="请选择">
            <el-option
              v-for="item in optionsEnv"
              :key="item.id"
              :label="item.env_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="环境变量状态" prop="delivery">
          <el-switch v-model="ruleFormEnvVar.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="环境变量描述" prop="description">
          <el-input type="textarea" v-model="ruleFormEnvVar.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEnvVar = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="envVarAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="envVarEdit">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryApiEnvVarList, addApiEnvVar, editApiEnvVar, deleteApiEnvVar } from '@/services/apiEnvVar.js'
import { queryEnvAll } from '@/services/apiEnv.js'
export default {
  name: 'ApienvVar',
  data () {
    return {
      isAdd: true,
      tableData: [{
        id: '1',
        var_key: '王小虎',
        description: '上海市普陀区金沙江路 1518 弄'
      }],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      formLabelWidth: '120px',
      dialogVisibleEnvVar: false,
      optionsEnv: [],
      ruleFormEnvVar: {
        var_key: '',
        var_value: '',
        description: '',
        env_id: '',
        status: '1'
      },
      apiEnvVarFormRules: {
        var_key: [
          { required: true, message: '请输入环境变量名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境变量描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择环境变量类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.loadApiEnvVarList()
    this.loadgetApiEnvAll()
  },
  methods: {
    async loadApiEnvVarList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryApiEnvVarList(queryObj)
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadgetApiEnvAll () {
      const queryObj = { query_type: 'query_all' }
      const { data: res } = await queryEnvAll(queryObj)
      if (res.status === 1) {
        this.optionsEnv = res.data
      }
    },
    // 环境变量信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadApiEnvVarList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadApiEnvVarList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      this.isAdd = true
      this.ruleFormEnvVar = {
        var_key: '',
        var_value: '',
        description: '',
        env_id: '',
        status: '1'
      }
      this.dialogVisibleEnvVar = true
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.ruleFormEnvVar = rowData
      this.dialogVisibleEnvVar = true
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { id: rowData.id }
        const { data: res } = await deleteApiEnvVar(deleteObj)
        if (res.status === 1) {
          this.$message.success('删除环境变量成功')
          this.loadApiEnvVarList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async envVarAdd () {
      const addEnvVarObj = this.ruleFormEnvVar
      const { data: res } = await addApiEnvVar(addEnvVarObj)
      if (res.status === 1) {
        this.dialogVisibleEnvVar = false
        this.$message.success('添加环境变量成功')
        this.loadApiEnvVarList()
      }
    },
    async envVarEdit () {
      const editEnvObj = this.ruleFormEnvVar
      const { data: res } = await editApiEnvVar(editEnvObj)
      if (res.status === 1) {
        this.dialogVisibleEnvVar = false
        this.$message.success('编辑环境变量成功')
        this.loadApiEnvVarList()
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefEnvVar.resetFields()
      this.loadApiEnvVarList()
    }
  }
}
</script>

<style>

</style>
